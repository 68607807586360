.cover-photo-continer-vendor {
  height: 200px !important;
  position: relative !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
  border: 0.5px solid #6a6565 !important;
}

.cover-photo-vendor {
  width: 100% !important;
  height: 200px !important;
  object-fit: cover !important;
}

.icon-button-file-input-vendor {
  display: none;
}

.chg-cover-photo-vendor {
  position: absolute !important;
  bottom: 10px !important;
  right: 10px !important;
  background-color: rgb(246, 246, 246) !important;
  opacity: 0.7 !important;
  color: #000 !important;
  font-size: 0.8rem !important;
}

.add-cover-photo-container-vendor {
  width: 50% !important;
  margin: auto !important;
  text-align: center !important;
  vertical-align: middle !important;
  padding-top: 60px;
}

.add-cover-photo-vendor {
  background-color: rgb(246, 246, 246) !important;
  color: #000 !important;
  opacity: 0.7 !important;
  margin-top: 10px !important;
  font-size: 0.8rem !important;
}

.display-container-vendor {
  position: relative;
  height: 30px !important;
}

.icon-continer-vendor {
  position: absolute;
  top: -50px !important;
  left: 20px !important;
  border-radius: 50%;
}

.icon-image-vendor {
  width: 100px !important;
  height: 100px !important;
  position: absolute !important;
  border-radius: 50% !important;
  padding: 5px;
  background-color: #fff !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
}

.icon-continer-vendor:hover .overlay-vendor {
  opacity: 1;
}

.overlay-vendor {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 5px !important;
  left: 5px !important;
  border-radius: 50%;
  padding: 5px;
  opacity: 0;
  transition: 0.3s ease;
  background-color: #00000094;
  width: 90px !important;
  height: 90px !important;
}

.overlay-display-vendor {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 5px !important;
  left: 5px !important;
  border-radius: 50%;
  padding: 5px;
  opacity: 1;
  transition: 0.3s ease;
  background-color: #fff;
  width: 90px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
  height: 90px !important;
}

.overlay-display-vendor .camera-icon-vendor {
  color: #000 !important;
}

.camera-icon-vendor {
  color: white !important;
  font-size: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
