.sa-thumbnail .thumbnail-item {
  height: 100px;
  width: 100px;
  margin: 10px !important;
  border-radius: 4px;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
  position: relative !important;
  cursor: pointer;
}

.sa-thumbnail .selected-item {
  height: 120px;
  width: 120px;
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.sa-thumbnail .selected-item:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.sa-thumbnail .next-icon,
.sa-thumbnail .previous-icon {
  font-size: 50px !important;
  color: #727f88;
  cursor: pointer;
}

.sa-thumbnail .previous-icon {
  transform: rotate(180deg);
}

.sa-thumbnail .video-thumbnail {
  font-size: 50px !important;
  margin-top: 25px;
  color: #727f88 !important;
}

.sa-thumbnail .image-thumbnail {
  height: 100px;
  width: 100px;
  border-radius: 4px;
}

.sa-thumbnail .pdf-thumbnail {
  font-size: 50px !important;
  margin-top: 25px;
  color: #d1d4d6 !important;
}

.sa-thumbnail .selected-item .image-thumbnail {
  height: 120px;
  width: 120px;
}

.sa-thumbnail .selected-item .video-thumbnail,
.sa-thumbnail .selected-item .pdf-thumbnail {
  margin-top: 33px !important;
}
.text-align-center {
  text-align: center !important;
}

.sa-thumbnail .thumbnail-disable {
  opacity: 0.2 !important;
}

.sa-thumbnail .remove-image-icon {
  position: absolute;
  color: #dc3545;
  top: -10px;
  right: -10px;
  cursor: pointer;
}
