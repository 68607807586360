.sa-file.file-name-tooltip {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 13px;
  cursor: pointer;
  margin: 10px;
  width: 100px;
  font-weight: 500;
  text-align: center;
}
