.react-tel-input {
  margin-top: 24px !important;
}
.react-tel-input .form-control {
  background-color: transparent !important;
  color: #bcccdc !important;
  border: none !important;
  border-bottom: 1px #334e68 solid !important;
  border-radius: 0px !important;

  padding-bottom: 12px !important;
  padding-top: 12px !important;
}

.react-tel-input .special-label {
  background: transparent !important;
  top: -24px !important;
  left: -5px !important;

  font-size: 15px !important;
  font-style: normal !important;
  text-align: left !important;
  font-family: Lato !important;
  font-weight: normal !important;
  line-height: normal !important;
  font-stretch: normal !important;
  letter-spacing: 0.25px !important;
  color: #8b98a4 !important;
}

.selected-flag {
  padding: 0px !important;
}
