.back-to-home-button {
  background-color: #fff !important;
}

.image-container {
  text-align: center !important;
}

.page-not-found {
  text-align: center !important;
  margin-top: 0px;
}

.back-to-home {
  text-align: center !important;
  margin-top: 30px;
}

.page-not-found-image {
  width: 300px;
  height: 200px;
}

.number-container {
  text-align: center !important;
  font-size: 64px !important;
  line-height: 70px !important;
  font-weight: bold !important;
}

.number-container .zero {
  margin: 0px 3px !important;
  color: #353637 !important;
}
.Oval {
  width: 100px;
  height: 14px;
  margin: 61px 0 0;
  background-color: #f2f2f2 !important;
  position: absolute;
  border-radius: 50%;
  left: 46%;
  top: 11px;
}
