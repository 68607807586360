.selected-file-container {
  border: solid 1px #334e68;
  background-color: #15202a;
  padding: 12px 18px;
  border-radius: 4px;
  margin-bottom: 18px !important;
}

.selected-file {
  width: 22px;
  height: 22px;
  fill: #bcccdc;
}

.file-list-item {
  padding: 0 !important;
}

.selected-file-container .MuiListItem-container {
  list-style: none;
}

.selected-file-container .MuiListItemSecondaryAction-root {
  margin-right: -16px;
}

.selected-file-meta-container {
  width: 100%;
}

.selected-file-name {
  width: calc(100% - 80px);
  margin-left: 8px !important;
}

.file-delete-icon {
  border: none !important;
}

.MuiDropzoneArea-textContainer {
  padding: 16px;
}

.MuiDropzoneArea-text.MuiTypography-h5 {
  font-size: 14px !important;
  margin: 0 0 16px 0;
}

.avoid-clicks {
  pointer-events: none;
}

.MuiDropzoneArea-icon {
  width: 32px !important;
  height: 32px !important;
}

.file-list-item .remove-file-icon {
  margin-left: auto !important;
  cursor: pointer;
}

.dropzone-label {
  margin-bottom: 5px !important;
}
