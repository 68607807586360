.sa-custom-file-upload .custom-file-upload-with-preview-container {
  padding: 0px !important;
}

.sa-custom-file-upload .preview-container {
  height: 350px !important;
  position: relative !important;
  border: 1px rgba(0, 0, 0, 0.2) solid;
  border-radius: 4px;
}
.sa-custom-file-upload .thumbnails-container {
  height: 130px !important;
  position: relative !important;
  margin-bottom: 20px;
}

.sa-custom-file-upload .file-upload-container {
  height: 130px !important;
  position: relative !important;
  padding: 15px;
}
