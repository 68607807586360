.vendor-avatar {
  height: 100px !important;
  width: 100px !important;
  margin: auto !important;
  text-transform: uppercase !important;
  font-size: 50px !important;
}

.avatar-container {
  text-align: center !important;
  position: relative;
}

.avatar-upload-container {
  position: relative;
  height: 100px !important;
  width: 100px !important;
  margin: auto !important;
  border-radius: 50%;
}

.vendor-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0px !important;
  right: 0px !important;
  border-radius: 50%;
  opacity: 0;
  transition: 0.3s ease;
  background-color: #00000094;
}

.vendor-overlay .camera-icon {
  color: white !important;
  font-size: 100px;
  position: absolute;
  top: 50% !important;
  left: 25% !important;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.avatar-upload-container:hover .vendor-overlay {
  opacity: 1;
}

.vendor-account-card-container {
  padding: 24px 24px !important;
  position: relative !important;
}

.vendor-title,
.vendor-description {
  margin-top: 10px !important;
}

.avatar-details-container {
  position: relative !important;
}
.vendor-account-edit-icon {
  cursor: pointer;
  float: right;
  margin-right: 24px !important;
  margin-top: 16px !important;
}

.vendor-account-publish-unpublish-icon {
  cursor: pointer;
}

.vendor-account-unpublish-icon {
  cursor: pointer;
  float: right;
  margin-right: 16px !important;
  margin-top: 10px !important;
}
.vendor-account-active-deactive-icon {
  cursor: pointer;
  float: right;
  margin-right: 24px !important;
  margin-top: 16px !important;
}

.icon-button-file-input {
  display: none;
}

.vendor-back-arrow-icon-container {
  position: absolute !important;
  top: -25px !important;
  left: -5px !important;
}

.vendor-back-arrow-icon {
  cursor: pointer !important;
  position: absolute !important;
  top: -15px !important;
  left: 10px !important;
}

.disable-btn {
  cursor: inherit !important;
}

.disable-btn {
  box-shadow: none !important;
}

.active-dot {
  vertical-align: text-top;
}

.active-dot::before {
  content: '•';
  font-size: 40px;
  width: 15px !important;
  height: 15px !important;
  color: green;
  border-radius: 50%;
  margin-right: 3px;
  line-height: 35px;
}

.edit-vendor-icon {
  float: right;
  margin-right: 0px !important;
}

@keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-ms-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-o-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
