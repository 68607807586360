.gallery-media-container {
  position: relative !important;
  width: 100%;
  height: 100% !important;
}
.gallery-media {
  width: 100%;
  height: 99% !important;
  border-radius: 4px;
}
.left-click-button {
  position: absolute;
  left: 0 !important;
  top: 40%;
  font-size: 100px !important;
  opacity: 0.4;
  cursor: pointer;
  z-index: 1000000000;
  color: #727f88;
}
.right-click-button {
  position: absolute;
  right: 0 !important;
  top: 40%;
  font-size: 100px !important;
  opacity: 0.4;
  cursor: pointer;
  z-index: 1000000000;
  color: #727f88;
}
.left-click-button:hover {
  opacity: 1 !important;
}
.right-click-button:hover {
  opacity: 1 !important;
}

.download-icon {
  font-size: 80px !important;
}
.grid-download-icon-label {
  height: 100%;
  display: table;
  width: 100%;
  height: 100%;
  color: #727f88 !important;
}

.download-container a {
  color: #727f88 !important;
  text-decoration: none !important;
}

.download-container {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}

.download-image-file-icon {
  color: #fff !important;
  text-decoration: none !important;
  position: absolute;
  right: 15px;
  bottom: 15px;
  cursor: pointer;
  font-size: 30px !important;
}
