a {
  text-decoration: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  cursor: pointer !important;
  text-decoration: underline;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: 'Lato';
  background-color: #1c1f21;
  color: #f9f9fa;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.margin-right-5 {
  margin-right: 5px !important;
}

.margin-top-32 {
  margin-top: 32px !important;
}

.margin-top-48 {
  margin-top: 47.8px !important;
}

.form-grid-container {
  background-color: #fff !important;
  padding: 20px !important;
  border-radius: 5px !important;
}

.react-tel-input .form-control {
  padding-bottom: 10px !important;
  padding-top: 10px !important;
  font-size: 14px !important;
  width: 100% !important;
}

div[class^='MTableToolbar-title'] {
  width: 100% !important;
}

.MuiToolbar-root div[class^='MTableToolbar-spacer-'] {
  flex: 1 1 0% !important;
}

.MuiToolbar-root div:first-child {
  flex: 1 1 0% !important;
}

/* .MuiTableCell-root {
  padding: 5px 10px !important;
} */

.tab-content {
  background-color: #15202a !important;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15) !important;
}

.invite-add-btn {
  float: right;
}

.cancel-btn {
  float: right;
  margin-right: 10px !important;
}

.action-icon {
  margin-right: 10px !important;
  cursor: pointer !important;
  vertical-align: top !important;
}

.MuiAppBar-root {
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15) !important;
}

.custom-table-container {
  background-color: #15202a !important;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15) !important;
}

.custom-table-container .remove-shadow {
  box-shadow: none !important;
}

.custom-table-container .table-header-title-container {
  padding: 20px 20px 10px 10px;
}

/* status classes */
.status-success {
  color: #628d2c !important;
  padding: 2px 6px 1px 8px !important;
  background-color: rgba(137, 195, 65, 0.15) !important;
  display: initial !important;
}

.status-danger {
  color: #f44336 !important;
  padding: 2px 6px 1px 8px !important;
  background-color: rgba(248, 50, 0, 0.1) !important;
  display: initial !important;
}

.status-info {
  color: #2fa3ba !important;
  padding: 2px 6px 1px 8px !important;
  background-color: rgba(60, 182, 206, 0.1) !important;
  display: initial !important;
}

.status-warning {
  color: #eb8315 !important;
  padding: 2px 6px 1px 8px !important;
  background-color: #fcf8e3 !important;
  display: initial !important;
}

.message-modal-note {
  margin-top: 10px;
}

.message-modal-actions-container {
  padding: 16px 24px 16px 24px !important;
}

.message-modal-content-container {
  padding-top: 16px !important;
}

.wrapperClassName {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
}

.editorClassName {
  padding: 10px 20px;
  min-height: 300px !important;
}

.back-arrow-icon {
  margin-right: 5px !important;
  cursor: pointer;
}

.html-tooltip-list {
  height: 130px !important;
  overflow-y: auto;
}

.error-message {
  color: #dc545c !important;
  margin-top: 4px !important;
  font-size: 12px !important;
  font-family: Lato !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
}

.red-dot {
  height: 15px !important;
  width: 15px !important;
  background-color: red !important;
  border-radius: 50% !important;
  display: inline-block !important;
  position: absolute !important;
  right: 5px !important;
  top: 5px !important;
}

.MuiFormHelperText-root {
  margin-left: 0px !important;
  margin-right: 0px !important;
  color: #8b98a4 !important;
}

.MuiFormHelperText-root.Mui-error {
  color: #dc545c !important;
}
.MuiFormHelperText-root.Mui-error.Mui-required {
  color: #dc545c !important;
}

.mention-required-error div textarea::-webkit-input-placeholder {
  color: #dc545c;
}

.mention-required-error div textarea:-moz-placeholder {
  color: #dc545c;
}

.mention-required-error div textarea::-moz-placeholder {
  color: #dc545c;
}

.mention-required-error div textarea:-ms-input-placeholder {
  color: #dc545c;
}
.mention-required-error div textarea::placeholder {
  color: #dc545c;
}

.mention-container__suggestions {
  background-color: transparent !important;
}

.handle-icon {
  margin-right: 15.4px !important;
}

.handle-type {
  font-size: 12px !important;
  color: #8b98a4 !important;
  text-transform: capitalize !important;
}

.mention-container__suggestions__list::-webkit-scrollbar-thumb {
  background: #4d9ce6;
  border-radius: 20px;
}

.mention-container__suggestions__list::-webkit-scrollbar {
  width: 4px;
  background-color: #15202a;
}
.handle-name {
  font-weight: 500 !important;
}

.see-more-less {
  font-weight: bold !important;
  color: #4d9ce6 !important;
  font-family: Lato !important;
  font-size: 15px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: 0.25px !important;
  cursor: pointer !important;
}

.cursor-pointer {
  cursor: pointer !important;
}
.feed-title-container.cursor-pointer textarea {
  cursor: pointer !important;
}

.input-adornment {
  cursor: pointer;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-rigth-5 {
  margin-right: 5px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.padding-10 {
  padding: 10px !important;
}

.stat-icon {
  display: inline-flex;
  line-height: 28px !important;
}

.clear-filter-button {
  float: right;
  margin-right: 10px !important;
}

.apply-filter-button {
  float: right;
}

.clear-filter-icon {
  margin-left: 5px !important;
}

.text-transform-none {
  text-transform: none !important;
}

.edit-icon-button {
  float: right;
  padding: 2px 12px !important;
}
.close-icon-button {
  float: right;
  padding: 2px 12px !important;
}

.chip-element-container {
  max-height: 70px !important;
}

.chip-element-container::after {
  content: '...';
  vertical-align: bottom;
}

.text-indent {
  text-indent: 3ch;
}

.set-full-height {
  height: 100% !important;
}

.text-align-center {
  text-align: center !important;
}

.text-align-left {
  text-align: left !important;
}

.text-align-right {
  text-align: right !important;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.table-cell-content {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 13px;
  cursor: pointer;
}

.table-title {
  font-weight: 600 !important;
  font-size: 14px;
}

.dispaly-inline-flex {
  display: inline-flex !important;
}

.hover-grid .grid-first-child {
  display: none;
}

.hover-grid .grid-second-child {
  display: block;
}

.hover-grid:hover .grid-first-child {
  display: block !important;
}

.hover-grid:hover .grid-second-child {
  display: none !important;
}

.MuiTypography-button {
  font-family: 'Lato' !important;
  text-transform: none !important;
  color: #00afef !important;
}

.surgeon-card-container {
  padding: 12px 24px 12px 16px !important;
  border-radius: 4px !important;
  background-color: #15202a !important;
  position: relative !important;
}

.background-upload-overlay {
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 111 !important;
}

.popper-set-zindex {
  z-index: 99999 !important;
}

.mentions__mention {
  position: relative;
  z-index: 1;
  color: #4d9ce6 !important;
  background-color: #15202a !important;
  white-space: none !important;
}

.mention-container input:focus {
  border-image: none !important;
  border: none !important;
  outline: none !important;
}

.mention-container input::placeholder,
.mention-container textarea::placeholder {
  color: #8b98a4;
  font-family: Lato;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
}

.mention-container input:-ms-input-placeholder,
.mention-container textarea:-ms-input-placeholder {
  color: #8b98a4;
  font-family: Lato;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
}

.mention-container input::-ms-input-placeholder,
.mention-container textarea::-ms-input-placeholder {
  color: #8b98a4;
  font-family: Lato;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
}

.toolbarClassName {
  background-color: transparent !important;
  border-color: rgba(0, 0, 0, 0.23) !important;
}

.form-custom-placeholder {
  font-size: 15px !important;
  font-style: normal !important;
  text-align: left !important;
  font-family: Lato !important;
  font-weight: 500 !important;
  line-height: normal !important;
  font-stretch: normal !important;
  letter-spacing: 0.15px !important;
  color: #8b98a4 !important;
  margin-bottom: 12px !important;
  margin-top: 24px !important;
}

.react_tinylink_card {
  height: auto !important;
  margin-top: 8px !important;
  border-radius: 4px !important;
  border: 1px #283745 solid !important;
  color: #bcccdc !important;
  background: transparent !important;
  font-size: 15px !important;
}

.react_tinylink_card_content_header {
  color: #fff !important;
  margin-right: 10px !important;
  margin-bottom: 5px !important;
  font-size: 15px !important;
}

.react_tinylink_card_content,
.react_tinylink_footer {
  color: #8b98a4 !important;
  margin-right: 10px !important;
}
.play-icon {
  height: 30px !important;
  width: 30px !important;
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.MuiPickersCalendarHeader-switchHeader button {
  background-color: transparent !important;
}

.MuiPickersCalendarHeader-daysHeader .MuiPickersCalendarHeader-dayLabel {
  color: inherit !important;
}

.MuiPickersDay-day.MuiPickersDay-current {
  color: inherit !important;
}

.MuiPickersDay-day.MuiPickersDay-current.MuiPickersDay-daySelected {
  color: #fff !important;
}

.MuiPickersClockPointer-pointer,
.MuiPickersClockPointer-thumb.MuiPickersClockPointer-noPoint {
  background-color: #bcccdc !important;
}

.MuiPickersClockNumber-clockNumberSelected {
  color: #15202a !important;
  background-color: #bcccdc !important;
}

.toolbarClassName {
  background-color: transparent !important;
  border-color: rgba(0, 0, 0, 0.23) !important;
}

.wrapperClassName {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  border-radius: 4px !important;
}
