.application-logo-img-div {
  text-align: center;
}

.application-logo-img {
  width: 172.9px !important;
  height: 42px;
  border-radius: 5px;
  flex-grow: 0;
}

.reset-password-form-outer-div {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: auto;
  position: absolute;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.reset-password-title {
  color: #ffffff !important;
}

.reset-passwords-field label {
  font-family: Lato !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: 0.25px !important;
  text-align: left !important;
  color: #627d98 !important;
}

.reset-password-form-inner-div {
  margin: auto;
}

div.reset-password-form-inner-div {
  width: 480px !important;
  padding: 64px 8% 64px 8%;
  background-color: #33393e !important;
  border-radius: 8px;
}

.back-to-login,
.back-to-login a {
  color: #fff !important;
}

.reset-password-form-button {
  margin-bottom: 25px !important;
  margin-top: 107px !important;
}

.sign-in-text {
  cursor: pointer !important;
}

@media only screen and (max-width: 767px) {
  .reset-password-form-button {
    margin-top: 180px !important;
  }
  div.reset-password-form-inner-div {
    height: 100%;
  }
}

@media only screen and (min-width: 1310px) {
  div.reset-password-form-inner-div {
    padding: 64px 4% 64px 4%;
  }
}
