.application-logo-img-div {
  text-align: center;
}

.application-logo-img {
  width: 172.9px !important;
  height: 42px;
  border-radius: 5px;
  flex-grow: 0;
}

.login-form-outer-div {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: auto;
  position: absolute;
}

.login-form-inner-div {
  margin: auto;
}

.login-title {
  color: #ffffff !important;
}

.login-field label {
  font-family: Lato !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: 0.25px !important;
  text-align: left !important;
  color: #627d98 !important;
}

.login-form-button {
  width: 100%;
}

.gena-name {
  font-size: 36px;
  color: black;
}

div.login-form-outer-div {
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

div.login-form-inner-div {
  width: 100% !important;
  padding: 30px 24px 30px 24px;
  background-color: #33393e !important;
  border-radius: 8px;
}

div.ant-row.ant-form-item {
  margin-bottom: 0 !important;
}

div.forgot-password {
  cursor: pointer;
  margin-bottom: 25px;
  margin-top: 28px;
}

.main-grid {
  padding: 0% 6.8% !important;
}

.child-grid-one {
  padding: 110px 83px 0px 83px !important;
}

.child-grid-two {
  padding: 45px 83px 0px 83px !important;
}

.learn-more-label {
  color: #d0ac74 !important;
  font-weight: 900 !important;
  margin-top: 49px !important;
}

.play-store-icon {
  margin-left: 40px !important;
}
.learn-more-label span {
  display: inline-flex !important;
}

.store-icons-container {
  margin-top: 50px !important;
}

.forward-icon {
  margin-left: 10px !important;
}

.footer-grid {
  border-top: 1px rgba(255, 255, 255, 0.15) solid;
  margin-top: 70px !important;
  padding: 42px 110px !important;
  display: flex;
}

.community-icon {
  height: 51px !important;
  width: 51px !important;
  margin-right: 26px !important;
}

.community-footer-link {
  padding: 2px 0px;
  color: rgba(255, 255, 255, 0.5) !important;
}

.form-header,
.app-icon-container-form-footer {
  display: none !important;
}

.copyright-info {
  margin-top: 28px !important;
  color: rgba(255, 255, 255, 0.15) !important;
}

@media only screen and (max-width: 767px) {
  div.login-form-inner-div {
    height: 100%;
    padding: 50px 24px 50px 24px;
    position: fixed;
  }
  .learn-more-label {
    margin-top: 57px !important;
    margin-bottom: 88px !important;
  }
  .main-grid {
    padding: 0px !important;
    bottom: 0 !important;
  }
  .child-grid-one {
    display: none !important;
  }
  .child-grid-two {
    height: 100% !important;
    padding: 0px !important;
  }
  .form-header {
    display: block !important;
  }
  .app-icon-container-form-footer {
    display: flex !important;
    margin-top: 20px !important;
  }
  .footer-grid {
    display: none !important;
  }
}

button.ant-btn.login-form-button.ant-btn-primary {
  margin-top: 15px;
}

.invalid-cred-text {
  color: #dc545c !important;
  font-family: Lato !important;
  font-size: 12px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  position: relative !important;
  bottom: -16px !important;
}

@media only screen and (min-width: 1310px) {
}
