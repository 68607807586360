.product-card-container {
  padding: 24px 24px !important;
}

.product-details-field-value {
  max-height: 100px !important;
  overflow-y: auto !important;
  text-align: justify;
  padding-right: 5px !important;
  padding: 5px 10px 5px 0px;
}

.product-details-field-value-description {
  padding-right: 5px !important;
  padding: 5px 10px 5px 0px;
}
.product-details-field-value::-webkit-scrollbar-thumb {
  background: #2c78f8;
  border-radius: 20px;
}

.product-details-field-value::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.product-irs-chip {
  margin-right: 10px;
}

.edit-product-icon {
  float: right;
}

.pg-driver-view img,
.pg-driver-view video {
  height: 80% !important;
  width: 100% !important;
}

.pg-viewer-wrapper {
  overflow-y: auto !important;
}

.close-preview-btn {
  float: right;
  padding: 0px !important;
}

.preview-dialog-form-content {
  padding: 24px 24px 40px 24px !important;
}
