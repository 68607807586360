.application-logo-img-div {
  text-align: center;
}

.application-logo-img {
  width: 172.9px !important;
  height: 42px;
  border-radius: 5px;
  flex-grow: 0;
}

.reset-password-form-outer-div {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: auto;
  position: absolute;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.reset-password-title {
  color: #ffffff !important;
}

.reset-passwords-field label {
  font-family: Lato !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: 0.25px !important;
  text-align: left !important;
  color: #627d98 !important;
}

.reset-password-form-inner-div {
  width: 450px;
  padding: 20px;
  margin: auto;
}

.force-reset-password-form-button {
  width: 100%;
}

.error-terms-condition-not-selected {
  color: #f5222d;
}
