.password-container {
  max-width: 450px;
  margin: auto;
}

.card-container {
  min-width: 275px !important;
}

.sivotec-page-content {
  padding: 16px !important;
}
