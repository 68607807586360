.avatar-cell-container {
  display: table;
}

.avatar-container,
.avatar-container-name {
  display: table-cell;
}

.avatar-role {
  margin-top: 5px;
  margin-right: 3px;
  font-size: 11px !important;
}

.avatar-container .MuiAvatar-root {
  text-transform: uppercase;
}

.avatar-details {
  padding-left: 12px;
}

.avatar-details-top {
  vertical-align: top;
}

.avatar-details-middle {
  vertical-align: middle;
}
