.application-logo-img-div {
  text-align: center;
}

.application-logo-img {
  width: 172.9px !important;
  height: 42px;
  border-radius: 5px;
  flex-grow: 0;
}

.forgot-password-form-outer-div {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: auto;
  position: absolute;
}

.forgot-password-title {
  color: #ffffff !important;
}

.forgot-passwords-field label {
  font-family: Lato !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: 0.25px !important;
  text-align: left !important;
  color: #627d98 !important;
}

.forgot-password-form-inner-div {
  margin: auto;
}

.forgot-password-form-forgot {
  float: right;
}

div.forgot-password-form-outer-div {
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

div.forgot-password-form-inner-div {
  width: 480px !important;
  padding: 64px 8% 64px 8%;
  background-color: #33393e !important;
  border-radius: 8px;
}

div.ant-row.ant-form-item {
  margin-bottom: 0 !important;
}

.back-to-login,
.back-to-login a {
  color: #fff !important;
}

.forgot-password-form-button {
  width: 100%;
  margin-bottom: 25px !important;
  margin-top: 147px !important;
}

.back-to-login-button {
  margin-top: 220px !important;
}

@media only screen and (max-width: 767px) {
  .forgot-password-form-button {
    margin-top: 220px !important;
  }
  .back-to-login-button {
    margin-top: 365px !important;
  }
  div.forgot-password-form-inner-div {
    height: 100%;
  }
}

@media only screen and (min-width: 1310px) {
  div.forgot-password-form-inner-div {
    padding: 64px 4% 64px 4%;
  }
}
